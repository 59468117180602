import React,{useState} from "react";
import {  UserOutlined, KeyOutlined } from "@ant-design/icons";
import { Button,  Form, Input,  message,Image, Row, Col,Typography } from "antd";
import { useNavigate } from "react-router-dom";
function Login() {
  const [details, setDetails] = useState({ username: "", password: "" });
  const [error, setError] = useState(null);

  let history = useNavigate();
  const { Title } = Typography;
  const onFinish = (values: any) => {
    fetch(`${localStorage.getItem("url")}/web/login/`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(details),
    })
      .then((data) => {
        if (!data.ok) {
          throw Error("Dados de acesso invalidos");
        }
        return data.json();
      })
      .then((data) => {
        localStorage.setItem("token", data.token);
        localStorage.setItem("username", data.nome_empresa);
        localStorage.setItem("status_", true);
        
        message.success(`Bem vindo(a) ${details.username}`);


        if (data.grupo === "empresa") {
          localStorage.setItem("log", true);
          localStorage.setItem("nivel", "operador");
          history("/home/Certidoes");
        }
        if (data.grupo === "arquivo") {
          localStorage.setItem("log", true);
          localStorage.setItem("nivel", "operador");
          history("/procura/home/dash");
        }
        if (data.grupo === "tribunal") {
          localStorage.setItem("log", true);
          localStorage.setItem("nivel", "contabilidade");
          history("/opera/home/dash");
          
        }
        if (data.grupo === "operario") {
          localStorage.setItem("log", true);
          localStorage.setItem("nivel", "contabilidade");
          history("/opera/home/dash");
        }
        if (data.grupo === "recargatest") {
          localStorage.setItem("log", true);
          localStorage.setItem("nivel", "contabilidade");
          history("/test/home");
        }
      })
      .catch((e) => {
        setError(e.message);
        message.error({
          content: "Credenciais inválidas! Verifique o nome de usuário ou a senha.",
          duration: 3, // Duration in seconds before the message closes automatically
        });
      });
  };
  return (
    <div className="flex flex-col items-center justify-center bg-white rounded-lg shadow-md w-full md:w-80 lg:w-70 ">
      {localStorage.setItem("url", "https://cob.ts.gov.mz/web")}
      <Image
        preview={false}
        style={{
          width: "80%",
          marginLeft: "15%",
        }}
        src={require("../../Assets/img/Group 3 1.png")}
        className="pb-10 pt-10"
      />
   
      <Form
        name="normal_login"
        initialValues={{ remember: true }}
        onFinish={onFinish}
        style={{ width: "90%" }}
      >
        <Form.Item name="nome de usuário">
          <Input
            placeholder="nome de usuário"
            style={{ width: "100%",borderRadius:'8px' }}
            size="large"
            onChange={(e) =>
              setDetails({ ...details, username: e.target.value })
            }
            value={details.username}
          />
        </Form.Item>
        <Form.Item name="password">
          <Input.Password
           
            type="password"
            placeholder="Password"
            style={{ width: "100%",borderRadius:'8px' }}
          
            size="large"
            onChange={(e) =>
              setDetails({ ...details, password: e.target.value })
            }
            value={details.password}
          />
        </Form.Item>

        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            className="login-form-button"
            style={{ width: "100%",backgroundColor:'#8A593E' }}
          >
            Acessar
          </Button>
        </Form.Item>

        <Form.Item>
          <Row justify="end">
            <Button size="large" type="link">
              Perdeu a senha ?
            </Button>
          </Row>

          <Row justify="end" className="fotn">
            <Col>
              Não tem uma conta?{" "}
              <Button size="large" type="link" onClick={()=>{history('/criar-conta')}}>
                Crie aqui.
              </Button>
            </Col>
          </Row>
        </Form.Item>
      </Form>
    </div>
  );
}

export default Login;
