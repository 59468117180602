import React, { useState, useEffect } from "react";
import {
  Button,
  Flex,
  Form,
  Input,
  Row,
  Col,
  Space,
  message,
  Select,
  Steps,
  Result,
} from "antd";
import {
  RightCircleOutlined,
  LeftCircleOutlined,
  CloseOutlined,
  RightOutlined,
  LeftOutlined,
} from "@ant-design/icons";
import Header from "../../Layout/Header";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import "../../styles/sigup.css";

function Criar() {
  const [form] = Form.useForm();
  const [form2] = Form.useForm();
  const [phase1, setPhase1] = useState("sd");
  const [phase2, setPhase2] = useState(null);
  const [phase3, setPhase3] = useState(null);
  const [phase4, setPhase4] = useState(null);
  const [nome, setNome] = useState(null);
  const history = useNavigate();
  const [pass, setPass] = useState(null);
  const [isLoading, setIsLoading] = useState(false); // Novo estado de carregamento
  const [details, setDetails] = useState({
    password: "",
    nome: "",
    nome_empresa: "",
    provincia: "Maputo",
    cidade: "Maputo",
    nuit: "",
    endereco: "",
    vocacao: "",
    cell: "",
    mail: "",
    tribunal: "",
  });
  const [requiredMark, setRequiredMarkType] = useState("optional");
  const onRequiredTypeChange = ({ requiredMarkValue }) => {
    setRequiredMarkType(requiredMarkValue);
  };

  const Registarempresa = () => {
    setIsLoading(true); // Inicia o loading
    axios({
      method: "post",
      url: `${localStorage.getItem("url")}/web/Registar_empresa/`,
      headers: {
        "Content-Type": "application/json",
      },
      data: details,
    })
      .then((dat) => {
        if (dat.status !== 200) {
          throw Error("Dados de acesso inv�lidos");
        }
        return dat;
      })
      .then((d) => {
        message.success("Registrado com sucesso!");
        setPhase3(null);
        setPhase4("df");
        setNome(d.data.username);
        setStep(2);
        setPass(d.data.password);
      })
      .catch((e) => {
        message.error("Registro sem sucesso!");
      })
      .finally(() => {
        setIsLoading(false); // Finaliza o loading
      });
  };

  const handleChange = (value) => {
    setDetails({ ...details, provincia: value });
    if (value === "MPM") {
      // Se "Maputo provincia" for selecionado, atualize tribunalOptions
      setTribunalOptions([
        {
          label: "Judicial da Provincia de Maputo",
          value: "Judicial da Provincia de Maputo",
        },
      ]);
    } else {
      // Se "Maputo cidade" for selecionado, atualize tribunalOptions
      setTribunalOptions([
        {
          label: "Judicial da cidade de Maputo",
          value: "Judicial da cidade de Maputo",
        },
      ]);
    }
  };
  const [tribunalOptions, setTribunalOptions] = useState([
    {
      label: "Judicial da cidade de Maputo",
      value: "Judicial da cidade de Maputo",
    },
    {
      label: "Judicial da Provincia de Maputo",
      value: "Judicial da Provincia de Maputo",
    },
  ]);

  const handleChange2 = (value) => {
    console.log(value);
    setDetails({ ...details, cidade: value });
  };

  const onFinish = () => {
    form
      .validateFields()
      .then(() => {
        // Todos os campos est�o preenchidos, voc� pode prosseguir
        setPhase1(null);
        setPhase2("sdsd");
      })
      .catch((errorInfo) => {
        // Valida��o falhou, alguns campos n�o est�o preenchidos
        message.error("Por favor, preencha todos os campos obrigat�rios.");
      });
  };
  const onFinish2 = () => {
    form
      .validateFields()
      .then(() => {
        // Todos os campos est�o preenchidos, voc� pode prosseguir
        setPhase2(null);
        setPhase3("sdsd");
        setStep(1);
      })
      .catch((errorInfo) => {
        // Valida��o falhou, alguns campos n�o est�o preenchidos
        message.error("Por favor, preencha todos os campos.");
      });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  const { Step } = Steps;
  const [step, setStep] = useState(0);
  const [visible, setVisible] = useState(null);
  useEffect(() => {
    function handleResize() {
      if (window.innerWidth < 768) {
        setVisible(null);
      } else {
        setVisible("12");
      }
    }

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div className="flex flex-col bg-white w-full h-screen max-h-screen">
      <Header />
      <div className="flex flex-col overflow-auto bg-white w-full md:w-2/3 lg:w-1/2 xl:w-2/5 mx-auto px-4 py-6">
        <div className="flex flex-col items-center justify-center bg-white rounded-lg shadow-xl p-10 ml-custom w-custom sm:w-another-md md:w-another-lg lg:w-another-xl xl:w-another-2xl ">
          {visible && (
            <>
              <Steps
                labelPlacement="vertical"
                current={step}
                size="small"
                style={{ marginLeft: "4%" }}
              >
                <Step title="Dados" />

                <Step title="Resumo" />
                <Step title="Conclu�do" />
              </Steps>
            </>
          )}

          <div className="bady_U w-full ">
            {phase1 && (
              <>
                <Form
                  name="basic"
                  className="pt-5"
                  form={form}
                  onFinish={onFinish}
                  layout="horizontal"
                  style={{
                    width: "100%",
                    backgroundColor: "white",
                  }}
                  initialValues={{ requiredMarkValue: requiredMark }}
                  onValuesChange={onRequiredTypeChange}
                  requiredMark={requiredMark}
                >
                  <Form.Item
                    name="username"
                    rules={[
                      {
                        required: true,
                        message: "Introduza o nome do representante!",
                      },
                    ]}
                  >
                    <Input
                      placeholder="Nome do Representante"
                      onChange={(e) =>
                        setDetails({ ...details, nome: e.target.value })
                      }
                      value={details.nome}
                      style={{ borderRadius: "8px" }}
                    />
                  </Form.Item>
                  <Form.Item
                    name="empname"
                    rules={[
                      {
                        required: true,
                        message: "Introduza o nome da empresa!",
                      },
                    ]}
                  >
                    <Input
                      placeholder="Nome da Empresa"
                      onChange={(e) =>
                        setDetails({
                          ...details,
                          nome_empresa: e.target.value,
                        })
                      }
                      value={details.nome_empresa}
                      style={{ borderRadius: "8px" }}
                    />
                  </Form.Item>
                  <Form.Item
                    name="nuit"
                    rules={[
                      { required: true, message: "Por favor, insira o NUIT!" },
                      {
                        pattern: /^[0-9]{9}$/,
                        message: "NUIT deve conter 9 d�gitos num�ricos.",
                      },
                    ]}
                  >
                    <Input
                      placeholder="NUIT"
                      onChange={(e) =>
                        setDetails({ ...details, nuit: e.target.value })
                      }
                      value={details.nuit}
                      style={{ borderRadius: "8px" }}
                    />
                  </Form.Item>

                  <Form.Item
                    name="business"
                    rules={[
                      { required: true, message: "Voca��o da empresa!" },
                    ]}
                  >
                    <Input
                      placeholder="Voca��o da Empresa"
                      onChange={(e) =>
                        setDetails({ ...details, vocacao: e.target.value })
                      }
                      value={details.vocacao}
                      style={{ borderRadius: "8px" }}
                    />
                  </Form.Item>
                  <Form.Item
                    name="Palavra-passe"
                    rules={[{ required: true, message: "Senha!" }]}
                  >
                    <Input.Password
                      placeholder="Palavra-passe"
                      onChange={(e) =>
                        setDetails({ ...details, password: e.target.value })
                      }
                      value={details.password}
                      style={{ borderRadius: "8px" }}
                    />
                  </Form.Item>

                  <Row gutter={16}>
                    <Col span={12} style={{ textAlign: "left" }}>
                      <Form.Item>
                        <Button
                          onClick={() => {
                            history("/");
                          }}
                          icon={<CloseOutlined />}
                        ></Button>
                      </Form.Item>
                    </Col>
                    <Col span={12} style={{ textAlign: "right" }}>
                      <Form.Item>
                        <Button
                          htmlType="submit"
                          icon={<RightOutlined />}
                        ></Button>
                      </Form.Item>
                    </Col>
                  </Row>
                </Form>
              </>
            )}

            {phase2 && (
              <>
                <Form
                  form={form2}
                  onFinish={onFinish2}
                  layout="horizontal"
                  className="provinicas_"
                  style={{
                    width: "100%",
                    backgroundColor: "white",
                  }}
                  initialValues={{ requiredMarkValue: requiredMark }}
                  onValuesChange={onRequiredTypeChange}
                  disabled={false}
                  requiredMark={requiredMark}
                >
                  <Form.Item
                    className="asq1"
                    label="Prov�ncia"
                    required
                    tooltip="Este � um campo obrigat�rio"
                  >
                    <Select
                      style={{ width: "100%" }}
                      onChange={handleChange}
                      options={[
                        {
                          value: "Maputo",
                          label: "Maputo cidade",
                        },
                        {
                          value: "MPM",
                          label: "Maputo prov�ncia",
                        },
                      ]}
                    />
                  </Form.Item>
                  <Form.Item
                    className="asq1"
                    label="Tribunal"
                    required
                    tooltip="Este � um campo obrigat�rio"
                  >
                    <Select
                      placeholder="Selecione o tribunal"
                      style={{ width: "100%" }}
                      onChange={handleChange2}
                      options={tribunalOptions}
                    />
                  </Form.Item>
                  <Form.Item
                    className="asq1"
                    name="phone"
                    rules={[
                      { required: true, message: "Insira o seu telefone" },
                    ]}
                  >
                    <Input
                      placeholder="Telefone"
                      onChange={(e) =>
                        setDetails({ ...details, cell: e.target.value })
                      }
                      value={details.cell}
                      style={{ borderRadius: "8px" }}
                    />
                  </Form.Item>
                  <Form.Item
                    className="asq1"
                    name="mail"
                    rules={[
                      {
                        required: true,
                        message: "Insira o seu email!",
                      },
                    ]}
                  >
                    <Input
                      placeholder="E-mail"
                      onChange={(e) =>
                        setDetails({ ...details, mail: e.target.value })
                      }
                      value={details.mail}
                      style={{ borderRadius: "8px" }}
                    />
                  </Form.Item>

                  <Form.Item
                    className="asq1"
                    required
                    tooltip="Este � um campo obrigat�rio!"
                  >
                    <Input
                      style={{ width: "100%", borderRadius: "8px" }}
                      placeholder="Morada"
                      onChange={(e) =>
                        setDetails({ ...details, endereco: e.target.value })
                      }
                      value={details.endereco}
                    />
                  </Form.Item>

                  <Row gutter={16}>
                    <Col span={12} style={{ textAlign: "left" }}>
                      <Form.Item>
                        <Button
                          onClick={() => {
                            history("/");
                          }}
                          icon={<LeftOutlined />}
                        ></Button>
                      </Form.Item>
                    </Col>
                    <Col span={12} style={{ textAlign: "right" }}>
                      <Form.Item>
                        <Button
                          htmlType="submit"
                          icon={<RightOutlined />}
                        ></Button>
                      </Form.Item>
                    </Col>
                  </Row>
                </Form>
              </>
            )}

            {phase3 && (
              <>
                <Form
                  layout="horizontal"
                  initialValues={{ requiredMarkValue: requiredMark }}
                  onValuesChange={onRequiredTypeChange}
                  disabled={true}
                  style={{
                    width: "100%",
                    backgroundColor: "white",
                  }}
                  requiredMark={requiredMark}
                >
                  <Flex vertical={true} gap={"small"}>
                    <Form.Item
                      className="resumo_items"
                      required
                      tooltip="Este � um campo obrigat�rio"
                    >
                      <Input
                        placeholder=""
                        onChange={(e) =>
                          setDetails({ ...details, nome: e.target.value })
                        }
                        value={details.nome}
                        style={{ borderRadius: "8px", width: "100%" }}
                      />
                    </Form.Item>
                    <Form.Item
                      className="resumo_items"
                      required
                      tooltip="Este � um campo obrigat�rio"
                    >
                      <Input
                        placeholder=""
                        onChange={(e) =>
                          setDetails({
                            ...details,
                            nome_empresa: e.target.value,
                          })
                        }
                        value={details.nome_empresa}
                        style={{ borderRadius: "8px" }}
                      />
                    </Form.Item>

                    <Form.Item
                      className="resumo_items"
                      required
                      tooltip="Este � um campo obrigat�rio"
                    >
                      <Input
                        placeholder=""
                        onChange={(e) =>
                          setDetails({ ...details, nuit: e.target.value })
                        }
                        value={details.nuit}
                        style={{ borderRadius: "8px" }}
                      />
                    </Form.Item>

                    <Form.Item
                      className="resumo_items"
                      required
                      tooltip="Este � um campo obrigat�rio"
                    >
                      <Input
                        placeholder=""
                        onChange={(e) =>
                          setDetails({ ...details, endereco: e.target.value })
                        }
                        value={details.endereco}
                        style={{ borderRadius: "8px" }}
                      />
                    </Form.Item>

                    <Form.Item
                      className="resumo_items"
                      required
                      tooltip="Este � um campo obrigat�rio"
                    >
                      <Input
                        placeholder=""
                        onChange={(e) =>
                          setDetails({ ...details, vocacao: e.target.value })
                        }
                        value={details.vocacao}
                        style={{ borderRadius: "8px" }}
                      />
                    </Form.Item>

                    <Form.Item
                      className="resumo_items"
                      required
                      tooltip="Este � um campo obrigat�rio"
                    >
                      <Input
                        placeholder=""
                        onChange={(e) =>
                          setDetails({ ...details, cell: e.target.value })
                        }
                        value={details.cell}
                        style={{ borderRadius: "8px" }}
                      />
                    </Form.Item>
                    <Form.Item
                      className="resumo_items"
                      required
                      tooltip="Este � um campo obrigat�rio"
                    >
                      <Input
                        placeholder=""
                        onChange={(e) =>
                          setDetails({ ...details, mail: e.target.value })
                        }
                        value={details.mail}
                        style={{ borderRadius: "8px" }}
                      />
                    </Form.Item>
                    <Form.Item
                      className="resumo_items"
                      required
                      tooltip="Este � um campo obrigat�rio"
                    >
                      <Select
                        defaultValue={details.provincia}
                        style={{ width: 180, borderRadius: "8px" }}
                        onChange={handleChange}
                        options={[
                          {
                            value: "Maputo",
                            label: "Maputo cidade",
                          },
                          {
                            value: "MPM",
                            label: "Maputo prov�ncia",
                          },
                        ]}
                      />
                    </Form.Item>
                  </Flex>
                </Form>

                {phase3 && (
                  <>
                    <Flex justify="space-between">
                      <Button
                        className="avanca1"
                        onClick={() => {
                          setPhase3(null);
                          setPhase2("dfdf");
                          setStep(0);
                        }}
                      >
                        <LeftOutlined />
                      </Button>

                      <Button
                        className="avancar2"
                        onClick={Registarempresa}
                        loading={isLoading} // Adicionado loading ao bot�o
                      >
                        Registar
                      </Button>
                    </Flex>
                  </>
                )}
              </>
            )}

            {phase4 && (
              <div className="Registado_com_sucesso">
                <Result
                  status="success"
                  title="Registrado com Sucesso"
                  subTitle={`username: ${nome}\npassword: ${pass}`}
                  extra={[
                    <Button
                      key="buy"
                      onClick={() => {
                        history("/");
                      }}
                    >
                      Login
                    </Button>,
                  ]}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Criar;
